<template>
  <div class="flex-column w-75 justify-content-center">
    <ion-range
      v-model="value"
      class="w-100 feedback-rating"
      aria-label="Feedback"
      :ticks="true"
      :snaps="true"
      :pin="showPin"
      :min="1"
      :max="range"
      @ionKnobMoveStart="markTouched"
    />
    <div class="mb-1 w-100 d-flex labels">
      <span class="left">{{ labelMin }}</span>
      <span class="right">{{ labelMax }}</span>
    </div>
    <ion-button class="mt-2 primary text-center" @click="submitAction"><span>Submit</span></ion-button>
  </div>
</template>

<script lang="ts" setup>
import { submitTaskFeedback } from '@/shared/actions/events';
import { toast } from '@/shared/native';

const emits = defineEmits(['submit']);
const value = ref(3);
const touched = ref(false);
const markTouched = () => {
  touched.value = true;
};
const props = defineProps({
  labelMin: {
    type: String,
    required: true,
  },
  labelMax: {
    type: String,
    required: true,
  },
  task: {
    type: String,
    required: true,
  },
  range: {
    type: Number,
    required: true,
  },
  showPin: {
    type: Boolean,
    default: false,
  },
});
const submitAction = async () => {
  if (!value.value || !touched.value) {
    await toast.show('Please adjust the slider before submitting.', 'nonative', 'danger');
    return;
  }
  try {
    await submitTaskFeedback(props.task, value.value);
    await toast.show('Feedback submitted', 'nonative', 'primary');
    emits('submit');
  } catch (e) {
    await toast.show('Error submitting feedback. Please try again later.', 'nonative', 'danger');
  }
};
</script>

<style lang="sass" scoped>
.flex-column
  justify-content: center
  align-content: center
  text-align: center
.feedback-rating
  min-width: 75%
.labels
  font-size: 0.9rem
  justify-content: space-between
  text-align: left
.right
  margin-left: auto
  text-align: right
ion-range
  --bar-background: var(--ion-color-primary)
  --bar-background-active: var(--ion-color-primary)
ion-button
  width: 100px
  margin: 0 auto
</style>
