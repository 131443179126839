<template>
  <ion-modal
    class="modal-big"
    css-class=""
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="close"
  >
    <div class="inner-content">
      <ion-button class="clickable-item-hov top-right" color="transparent" @click.prevent="close">
        <i class="ti-close close-button" />
      </ion-button>
      <div style="height: 40px"></div>
      <h3 class="text-center" v-html="header"></h3>
      <div v-for="(f, characterIndex) in fields.filter((f) => f?.length)" class="d-flex flex-column px-4 py-2">
        <div v-for="(field, fieldIndex) in f" class="my-1">
          <p v-if="!fieldIndex" class="bold">Details for {{ characters[characterIndex]?.info?.name }}</p>
          <p v-if="field.length === 2">{{ toTitleCase(field[0]) }}:&nbsp;{{ toTitleCase(field[1]) }}</p>
          <p v-else-if="field.length === 1">{{ toTitleCase(field[0]) }}</p>
          <div v-if="field.length === 1">
            <div v-if="useBodyOnlyKeys.includes(field[0])">
              <div class="editor">
                <Editor
                  v-model="mutableValues[characterIndex][field[0]]"
                  :api-key="`${tinyMCEKey}`"
                  :init="{
                    height: 350,
                    relative_urls: false,
                    toolbar:
                      'undo redo | bold italic backcolor | fontfamily |  \
                        bullist numlist outdent indent | removeformat',
                    contextmenu: false,
                    quickbars_insert_toolbar: false,
                    quickbars_selection_toolbar: 'bold italic underline link',
                  }"
                />
              </div>
            </div>
            <ion-input v-else v-model="mutableValues[characterIndex][field[0]]" class="c-input mr-3" type="text" />
          </div>
          <div v-else-if="field.length === 2">
            <ion-input v-model="mutableValues[characterIndex][field[0]][field[1]]" class="c-input mr-3" type="text" />
          </div>
        </div>
      </div>
      <div class="d-flex mt-3 mb-4 save-container">
        <ion-checkbox v-model="saveDataToCharacters" class="text-center" label-placement="end"
          ><span class="no-select">Add these details to character profile</span></ion-checkbox
        >
      </div>
      <div class="d-flex my-3 save-container">
        <ion-button class="save" @click="saveAndClose"><span class="no-select">Submit</span></ion-button>
      </div>
    </div>
  </ion-modal>
</template>

<script lang="ts" setup>
import { addInfoFields } from '@/shared/actions/characters';
import { toTitleCase } from '@/shared/utils/string';
import { Character } from '@/shared/types/static-types';
import { toast } from '@/shared/native';

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  header: {
    type: String,
    default: '',
  },
  fields: {
    type: Object as PropType<String[][]>,
    default: () => [],
  },
  characters: {
    type: Object as PropType<Character[]>,
    default: () => [],
  },
});

const saveDataToCharacters = ref(true);
const mutableValues = ref([] as any[]);
const {
  public: { tinyMCEKey },
} = useRuntimeConfig();
const useBodyOnlyKeys = ['description', 'backstory'];
const emits = defineEmits(['saveAndClose', 'close', 'directSubmitClose']);
const close = () => {
  emits('close');
};
const isOpen = toRef(props, 'isOpen');
const fields = toRef(props, 'fields');
const characters = toRef(props, 'characters');

const saveAndClose = async () => {
  const doSave = saveDataToCharacters.value;
  if (doSave) {
    let toSave = [] as any[];
    for (var i = 0; i < mutableValues.value.length; i++) {
      const character = characters.value[i];
      const fields = mutableValues.value[i];
      const toAdd = { id: character.id, info: { ...fields } };
      toSave.push(toAdd);
    }
    toSave = toSave.filter((c: any) => !isEmpty(c.info));
    const reses = await Promise.all(toSave.map((c: any) => addInfoFields(c.id, c.info)));
    await toast.show('Character details saved', 'nonative', 'primary');
    emits('saveAndClose', reses);
  } else {
    const toSend = {} as any;
    for (var i = 0; i < mutableValues.value.length; i++) {
      const character = characters.value[i];
      const fields = mutableValues.value[i];
      toSend[character.id as string] = fields;
    }
    emits('directSubmitClose', toSend);
  }
};

const onFieldsChanged = (characterFields: any, oldFields: any) => {
  // fields: [[a,b], [c], [d,e]], [[c]]
  if (isEqual(characterFields, oldFields)) {
    return;
  }
  mutableValues.value = [];
  for (let i = 0; i < characterFields.length; i++) {
    const toAdd = {} as any;
    const nestedField = characterFields[i]?.find((f: String[]) => f && f.length === 2);
    if (nestedField) toAdd[nestedField[0]] = {};
    mutableValues.value.push(toAdd);
  }
};
watch(fields, onFieldsChanged, { deep: true, immediate: true });
</script>

<style lang="sass" scoped>
.close-button
  width: 50px
.no-space
  margin-left: 0
  margin-right: 0
  margin-bottom: 0

.no-space [class*='col-']:not(:first-child),
.no-space [class*='col-']:not(:last-child)
  padding-right: 0
  padding-left: 0
.see-all-btn
  --border-radius: 10px
  height: 30px
.row
  margin-top: 10px !important

.one-character
  img
    min-width: 90px
    width: 90px !important
    height: 90px !important
    border-radius: 45px
    border: solid gray 0.1px
    object-fit: cover
    .name
      font-size: 18px
      font-weight: bold
      color: #737373
      cursor: pointer
    .name:hover
      opacity: 0.7

.title
  font-weight: bold
  font-size: 22px
.modal-big
  --width: 700px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
    --height: 100%
    --border-radius: 0px

.inner-content
  background: var(--ion-background-color, #fff)
  max-height: 95vh
  overflow: auto
  @media(max-width: 576px)
    max-height: 100vh
.top-right
  display: block
  float: right
  margin-top: 0.75rem
  width: 60px
  clear: both
.clickable-item-hov
  background: none
.close-button
  color: gray
.save-container
  align-content: center
  justify-content: center
.save
  width: 200px
h3
  position: relative
</style>
