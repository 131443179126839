<template>
  <div class="submission-card" :class="{ 'set-height': !isEmpty(submission.submission_character)}">
    <div class="card-inner">
      <div class="card-main clickable-item-hov" @click="handlePress">
        <img
          v-if="submission.submission_image_url"
          :src="resizeUpload(submission.submission_image_url || '', '250x250')"
        />
        <div v-else-if="submission.submission_character" class="submission-character-header">
          <div class="profile-ring" v-if="submission.submission_character?.profile_ring?.image">
            <img :src="submission.submission_character?.profile_ring?.image" class="ring-image" />
          </div>
          <div class="profile-picture">
            <img
              loading="lazy"
              v-character-image
              :src="resizeUpload(submission.submission_character?.info?.cropProfilePicture || '', '190h')"
              class="character-image" 
            />
          </div>
        </div>
        <div v-else-if="submission.submission_text" class="text-preview p-2">
          <!-- <div class="fade"></div> -->
          <p class="">{{ truncate(submission.submission_text, { length: 100 }) }}</p>
        </div>
      </div>
      <div class="w-100 bottom-row">
        <div v-if="!isEmpty(submission.submission_character)" class="d-flex flex-column mt-1">
          <a class="d-block link" :href="charProfile" target="_blank" style="overflow-wrap: anywhere">{{
            get(submission.submission_character, 'info.name')
          }}</a>
          <small class="d-flex justify-content-center">
            <span class="mx-1">by</span>
            <a class="d-block link" :href="userProfile" target="_blank">@{{ submission.username }}</a>
          </small>
        </div>
        <a v-else class="d-block link username" :href="userProfile" target="_blank">@{{ submission.username }}</a>
        <reaction
          class="rxn reaction"
          :reactions="submission.reaction_counts"
          :user-reaction="submission.user_reaction"
          :left-indent-popup="leftIndentPopup"
          :right-indent-popup="rightIndentPopup"
          @changed="(reaction) => reactionChanged(reaction.key, submission, reaction.isInstant)"
        >
          <ion-button color="transparent" class="icon-button">
            <div
              class="d-flex align-items-center justify-content-center text-primary"
              :class="{
                'text-secondary': !!submission.user_reaction && submission.user_reaction.reaction,
              }"
            >
              <i class="ti-thumb-up mr-2" />
              <span class="reaction-count">{{ submission.reaction_counts?.total_count || 0 }}</span>
            </div>
          </ion-button>
        </reaction>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { SiteEventTaskSubmission } from '@/shared/types/static-types';
import Reaction from '@/shared/components/Reaction/index.vue';
import store from '@/shared/store';
import { authStore } from '@/shared/pinia-store/auth';
import { toast } from '@/shared/native';
import { resizeUpload } from '@/shared/utils/upload';
import ProfileRing from '@/shared/components/ProfileRing.vue';

const emits = defineEmits(['reaction']);
const props = defineProps({
  submission: {
    type: Object as PropType<SiteEventTaskSubmission>,
    required: true,
  },
  onPress: {
    type: Function as PropType<(x: SiteEventTaskSubmission) => void>,
    required: false,
    default: () => {},
  },
  leftIndentPopup: {
    type: Boolean,
    required: false,
    default: false,
  },
  rightIndentPopup: {
    type: Boolean,
    required: false,
    default: false,
  },
});
const submission = toRef(props, 'submission');
const onPress = toRef(props, 'onPress');
const userProfile = computed(() => `/profile/${submission.value.username}`);
const charProfile = computed(() => `/character/${get(submission.value.submission_character, 'slug')}`);

const handlePress = () => {
  if (!onPress.value || !submission.value) return;
  if (submission.value?.submission_image_url) onPress.value(submission.value);
  else if (submission.value?.submission_text) onPress.value(submission.value);
  else if (submission.value?.submission_character) onPress.value(submission.value);
};

const reactionChanged = async (reaction: string, submission: SiteEventTaskSubmission, isInstant: boolean) => {
  const { isAuthenticated } = authStore();
  if (!isAuthenticated.value) {
    toast.show('Sign in to react to content!', 'nonative', 'primary');
    return;
  }
  if (!submission) return;
  const res = (await store.dispatch('SiteEventModule/reactToTaskSubmission', { reaction, submission })) as any;
  const oldReaction = submission.user_reaction;
  if (reaction && !oldReaction) {
    // react
    emits('reaction', { reaction, reactionId: res.newReaction.id, submission });
  }
  if (reaction && oldReaction) {
    // edit
    emits('reaction', { reaction, reactionId: res.newReaction.id, submission });
  }
  if (!reaction && oldReaction) {
    // unreact
    emits('reaction', { submission });
  }
};
</script>

<style lang="sass" scoped>
.set-height
  height: auto !important
.dark
  .submission-card
    background-color: #11022a
  a
    color: white
.submission-card
  width: 100%
  height: 210px
  max-width: 350px
  border-radius: 12px
  background-color: white
  margin: 0 auto
  display: inline-block
.card-main
  position: relative
  img
    object-fit: cover
    width: 100% !important
    height: 140px
    border-radius: 12px 12px 0 0
.icon-button
  color: #ae38e5
  font-size: 20px
  cursor: pointer
  .d-flex
    font-size: 20px
.icon-button:hover
  opacity: 0.7
.reaction-count
  font-size: 18px
  font-weight: bold
.bottom-row
  display: flex
  flex-direction: column
  position: relative
  align-items: center
a:hover
  opacity: 0.7
.text-preview
  min-height: 140px
  max-height: 140px
  // border-bottom: 1px solid rgba(120, 120, 120, 0.2)
  overflow: hidden !important
  white-space: pre-wrap
  display: block
  p
    padding-bottom: 1rem
.fade
  position: absolute
  bottom: 0
  left: 0
  right: 0
  height: 50px
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 100%)
.dark .fade
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #11022a 100%)

@media(max-width: 768px)
  img
    height: 180px
  .submission-card
    height: 210px

.username
  @media(min-width: 768px)
    margin-right: auto
    padding-inline: 0.5rem

.reaction
  @media(min-width: 768px)
    margin-left: auto

.submission-character-header
  border-radius: 20px
  margin-top: 1.25rem
  margin-bottom: 1.5rem
  overflow: hidden
  img
    height: auto !important
    border-radius: unset !important
  .profile-picture
    width: 112px !important
    height: 112px !important
    border: 6.4px rgba(0,0,0,0) solid
    position: relative
    border-radius: 50%
    z-index: 1
    overflow: hidden
    margin: 0px auto 0 auto !important
    .character-image
      object-fit: cover
      width: 100% !important
      height: 100% !important
  .profile-ring
    width: 145.6px !important
    top: 3px !important
    left: 50% !important
    transform: translateX(-50%) !important
    position: absolute
    z-index: 2
  .ring-image
    top: -16.8px !important
    position: absolute !important
    object-fit: cover
</style>
