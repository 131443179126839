<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <div class="modal-inner-content">
      <ion-header translucent>
        <ion-toolbar>
          <ion-title class="title">{{ modalTitle }}</ion-title>
          <ion-buttons slot="end">
            <ion-button class="close-button" @click="dismissModal">
              <i class="ti-close" />
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <div class="d-flex justify-content-center align-items-center pb-3 px-3">
        <div class="h-100 w-100">
          <div class="d-flex justify-content-center align-items-center h-75 w-100 mb-3">
            <div class="preview mt-3">
              <p>{{ text }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ion-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({
  name: 'TextPreviewModal',
})
export default class TextPreviewModal extends Vue {
  @Prop({ default: () => '', required: false }) modalTitle!: string;
  @Prop({ default: false }) isOpen!: boolean;
  @Prop({ required: false, default: () => '' }) text!: string;

  public get windowWidth() {
    return window.innerWidth;
  }

  public dismissModal() {
    this.$emit('dismissModal');
  }

  public get isMobSmallScreen() {
    return this.windowWidth <= 500;
  }

  @Watch('$route')
  public routeChanged() {
    this.$emit('dismissModal');
  }
}
</script>

<style lang="sass" scoped>
.modal-inner-content
  background: var(--ion-background-color, #fff)
  max-height: 98vh
  overflow: auto
  overflow-x: hidden
.preview
  max-width: 100%
  max-height: 400px
  p
    white-space: pre-wrap
.close-bottom
  position: absolute
  bottom: 1.5rem
  left: 0
  right: 0
  margin-left: auto
  margin-right: auto
  width: 100%
  text-align: center
  div
    display: inline-block
.close-button
  width: 50px
.modal-big
  --width: 600px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
</style>
